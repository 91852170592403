import React, { useRef } from 'react';
import { Form, Input, Modal, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customToast } from '../utils';

export default function LoginModal({ open, handleCancel }) {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { authLoading } = useSelector((state) => state.auth);

	const onFinish = async (data) => {
		const res = null

		if (res?.payload?.success) {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
			});
			await navigate('/services');
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
			});
		}
	};
	return (
		<>
			<Modal
				centered
				className='modal-btn mod-height mod-footer'
				title='Login to continue'
				open={open}
				onCancel={handleCancel}
				width={850}
				footer={false}>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
						width: '700px',
					}}
					form={form}>
					<h1>Your session has ended</h1>
					<h5 className='mb-5'>Log in to continue </h5>

					<Form.Item
						label='Email'
						name='usrEmail'
						rules={[
							{
								required: true,
								message: 'Please input your email',
							},
						]}>
						<Input className='input' type='email' />
					</Form.Item>
					<Form.Item
						label='Password'
						name='usrEncryptedPassword'
						rules={[
							{
								required: true,
								message: 'Please input your password',
							},
						]}>
						<Input.Password className='input' type='password' />
					</Form.Item>

					<button
						disabled={authLoading}
						className='cstm-btn my-5'
						type='submit'>
						{authLoading ? <Spin /> : 'Login'}
					</button>
				</Form>
			</Modal>
		</>
	);
}
