import { Tabs } from 'antd';
import ApplicationNameGuidelinesTab from '../company_forms/name_clearance/help_tabs/ApplicationNameGuidelinesTab';
import ApplicationNameGuideFeesTab from '../company_forms/name_clearance/help_tabs/ApplicationNameGuideFeesTab';
import HelpStep from '../../components/HelpStep';
 

export default function NameClearanceHelp() {
	const tabItems = [
		{
			label: 'Guidelines',
			key: '1',
			children: <ApplicationNameGuidelinesTab />,
		},
		{
			label: 'Fees',
			key: '2',
			children: <ApplicationNameGuideFeesTab />,
		},

		{
			label: 'Help',
			key: '3',
			children: <HelpStep />,
		},
	];

	return (
		<>
	
		 {/* <div className='flex justify-between w-full'>
		 <div className='label_1'>Service</div>
		 <div className='label_1'>Amount</div> 
		 </div>  */}
 

			<Tabs className='underlined_tab' defaultActiveKey='1' items={tabItems} />
		</>
	);
}
