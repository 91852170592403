import { Suspense, useEffect, useState } from 'react';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navigate,
	Outlet,
	Route,
	Routes,
	useLocation,
	useSearchParams,
} from 'react-router-dom';
import { Puff } from 'react-loader-spinner';
import axiosInstance from './instance';
import { ProtectedRoute } from './ProtectedRoute';
import DashBoard from './pages/dashboard/Dashboard';
import ApplicationHistory from './pages/history/ApplicationHistory';
import ViewApplicationHistory from './pages/history/ViewApplicationHistory';
import Payment from './pages/payment/Payment';
 
import { logout, setToken, setUser } from './features/auth/authSlice';
import { jwtDecode } from 'jwt-decode';
import ApplicationOfPdl from './pages/company_forms/name_clearance/ApplicationOfPdl';
import PdlOverviewPage from './pages/company_forms/name_clearance/PdlOverviewPage';
import PaymentFinish from './pages/payment/PaymentFinish';
import DlApplicationForm from './pages/driving_license/DlApplicationForm';
import DlOverviewPage from './pages/driving_license/DlOverviewPage';
import ApplicationOfDl from './pages/driving_license/ApplicationOfDl';

function App() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { pathname } = useLocation();

	const { isLoggedIn, token } = useSelector((state) => state.auth);

	const [usrToken, setusrToken] = useState(searchParams.get('userId'));

	async function handleInit() {
		if (usrToken) {
			await dispatch(setToken(usrToken));
			axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${usrToken}`;
			const decoded = await jwtDecode(usrToken);
			await dispatch(setUser(decoded));
		} else {
			// handleFetchInstitutionObj();
			return;
		}
	}

	const isTokenExpired = (token) => {
		if (!token && !usrToken) return true;

		const decodedToken = token?.length && jwtDecode(token);
		const currentTime = Date.now() / 1000;

		if (decodedToken?.exp < currentTime) {
			return true;
		}

		return false;
	};

	async function handleLogout() {
		await dispatch(logout());
		await localStorage.clear();
		window.location = await 'https://ecitizen.ndovupay.org/account/login';
	}

	const SuspenseLayout = () => (
		<Suspense
			fallback={
				<Puff
					visible={true}
					height='80'
					width='80'
					color='#4fa94d'
					ariaLabel='puff-loading'
					wrapperStyle={{}}
					wrapperClass=''
				/>
			}>
			<Outlet />
		</Suspense>
	);

	useEffect(() => {
		handleInit();
	}, []);

	useEffect(() => {}, [isLoggedIn]);

	useEffect(() => {
		axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}, [token, usrToken]);

	useEffect(() => {
		if (isTokenExpired(token)) {
			handleLogout();
		}
	}, [token, pathname]);

	return (
		<Routes>
			<Route element={<ProtectedRoute />}>
				<Route element={<SuspenseLayout />}>
					{/* <Route path='*' element={<Navigate to='/services' replace />} /> */}
					<Route path='' element={<DashBoard />} />
					<Route path='/services' element={<DashBoard />} />
					<Route
						path='/services/pdl'
						element={<PdlOverviewPage />}
					/>
					<Route
						path='/services/pdl/application'
						element={<ApplicationOfPdl />}
					/>
					<Route
						path='/services/application-history'
						element={<ApplicationHistory />}
					/>
					<Route
						path='/services/application-history/view-application'
						element={<ViewApplicationHistory />}
					/>
					<Route path='/services/payment' element={<Payment />} />
					<Route path='/payment-finish' element={<PaymentFinish />} />

					<Route path='/services/dl/application' element={<ApplicationOfDl />} />
					<Route path='/services/dl' element={<DlOverviewPage />} />
					

					
					
				</Route>
			</Route>
		</Routes>
	);
}

export default App;
