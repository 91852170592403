import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Form, Input, Select, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
 
import moment from "moment";
import toast from "react-hot-toast";
import { pdlClassData } from "../../data";
import { fetchProvinces, save } from "../../features/services/serviceSlice";
import { setDlDetails } from "../../features/services/dlSlice";

export default function DlApplicationForm({ handleNext }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { fetchObj } = useSelector((state) => state.fetchId);
  const { provincesData, singlePdl, registrationData, saving, servTypeId } =
    useSelector((state) => state.serv);
    const {dlDetails,pdlData} =  useSelector((state)=>state.dl)

 
  const [formData, setFormData] = useState(dlDetails);

  function onMonthChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      servExamDate: value,
    }));
  }

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onFinish = async () => {
    if (!formData?.servLocation) {
      toast.error("Select location");
      return;
    } 
    if (!formData?.servExamDate) {
      toast.error("Select exam date");
      return;
    }
    
    const res = await dispatch(
      save({
        servId: Object.keys(singlePdl)?.length > 0 ? singlePdl?.servId : null,
        servUsrId: "8d5d5ee5-1ceb-47c5-b07e-006341f9c2a8",
        servType: "PDL",
        servLocation: formData?.servLocation,
        servPdlClass: formData?.servPdlClass,
        servExamDate: formData?.servExamDate,
        servUser: "Job Kinyua",
        servMenuId: servTypeId,
        servServId:pdlData?.servId,
    

        url: "serv/save_service",
      })
    );
    if (res?.payload?.success) {
      toast.success(res.payload?.messages?.message);
      await dispatch(setDlDetails(formData));
      handleNext();
    } else {
      toast.error(res.payload?.messages?.message);
    }
  };

  async function fetchProvincesData() {
    dispatch(fetchProvinces());
  }

  useEffect(() => {}, [formData]);
  useEffect(() => {
    setFormData(registrationData);
  }, [registrationData]);

  useEffect(() => {
    fetchProvincesData();
  }, []);

  return (
    <>
      <div className="mt-[35px] w-full">
        <div className="mt-[40px] h-full mb-10">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            scrollToFirstError
            style={{
              maxWidth: "100%",
              marginTop: "2.5rem",
            }}
            form={form}
            onFinish={onFinish}
          >
            <div className="grid grid-cols-2 gap-x-[24px] w-full">
              <Form.Item
                label="Location"
                extra={"Choose option"}
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
              >
                <Select
                  name="servLocation"
                  value={formData?.servLocation}
                  className="activity-select-tag activity-select-icon"
                  allowClear
                  style={{
                    width: "100%",
                    height: "3.25rem",
                  }}
                  onChange={(value) => {
                    handleSelectChange(value, "servLocation");
                  }}
                  options={provincesData?.map((item) => ({
                    value: item?.provPlace,
                    label: item?.provPlace,
                  }))}
                  onDropdownVisibleChange={() => {
                    fetchProvincesData();
                  }}
                />
              </Form.Item>

         

            <Form.Item label="Exam Date" className="login-form-item">
              <DatePicker
                name="servExamDate"
                style={{
                  width: "100%",
                }}
                className="mr-3  input"
                format={"DD-MM-YYYY"}
                placeholder={
                  formData?.servExamDate
                    ? moment(formData.servExamDate)?.format("DD-MM-YYYY")
                    : "Select Date"
                }
                onChange={onMonthChange}
              />
            </Form.Item>
     
            </div>
            <div className="mt-[52px] flex items-center justify-center flex-col gap-y-[4px] mb-[40px]">
              <div className="w-[184px]">
                <button disabled={saving} type="submit" className="cstm-btn">
                  {saving ? <Spin /> : "Next"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
