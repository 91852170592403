import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceRequirements } from "../../features/services/serviceSlice";
import BlackNav from "../../components/BlackNav";
import { fetchPDl } from "../../features/services/dlSlice";
import { dateForHumans } from "../../utils";
import toast from "react-hot-toast";

const items = [
  {
    title: <Link to="/services">Services</Link>,
  },
  {
    title: "Application for Driving License",
  },
];

export default function DlOverviewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { servRequirementData, servTypeId } = useSelector(
    (state) => state.serv
  );
  const { pdlData } = useSelector((state) => state.dl);

  function handleApply() {
    if(Object.keys(pdlData)?.length < 0){
      toast.error("Apply pdl first")
    }else{
      navigate("/services/dl/application");
    }
   
  }

  function fetchServiceRequirementsData() {
    dispatch(
      fetchServiceRequirements({
        id: servTypeId,
      })
    );
  }

  function fetchDlData() {
    dispatch(fetchPDl());
  }

  useEffect(() => {
    fetchServiceRequirementsData();
    fetchDlData();
  }, []);

  console.log("pdlData ", typeof(pdlData));
  return (
    <>
      <BlackNav text={"Roads Transport Safety Authority"} />
      <div className="mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem] h-auto w-full">
        <Breadcrumb items={items} />

        <div className="heading_4 mt-[1.94rem] !text-blk21 capitalize">
          Application for Driving License
        </div>

        {servRequirementData?.length > 0 &&
          servRequirementData?.map((item) => (
            <>
              <div className="label_1 mt-[1.69rem]">{item?.serColumn}</div>

              <div className="label_2 mt-[1.2rem] flex flex-col">
                {item?.serDesc.split(",")?.map((sentence, index) => (
                  <div key={index}>
                    {sentence.trim()}
                    {index !== item?.serDesc?.split(",").length - 1 && <br />}
                  </div>
                ))}
              </div>
            </>
          ))}

        <div className="label_1 mt-[1.69rem]">
          Provisional Driving License Details
        </div>
        <div className="grid grid-cols-2 w-[50%] mt-[1.5rem] gap-y-[20px]">
          <div className="label_1">Pdl Class</div>
          <div className="">{pdlData?.servPdlClass}</div>

          <div className="label_1">Pdl Location</div>
          <div className="">{pdlData?.servLocation}</div>

          <div className="label_1">Pdl Exam Date</div>
          <div className="">{dateForHumans(pdlData?.servExamDate)}</div>
        </div>

        <div className="flex flex-col justify-center items-center text-center mt-[5rem] mb-[5rem]">
          <div className="w-[200px]">
            <button onClick={handleApply} className="cstm-btn ">
              Apply
            </button>
            <button
              onClick={() => navigate("/services")}
              className="cstm-btn-borderless"
            >
              Back to services
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
