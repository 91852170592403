import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	fetching: false,
	categories: [],
	natureOfBusiness: [],
	nameClearanceApplications: [],
	orderSummary: [],
};

export const fetchCategory = createAsyncThunk('fetchSlice/fetch', async () => {
	const res = await axiosInstance
		.get(`${url}/v1/api/category`)
		.then((res) => res.data.data.result);
	return res;
});

export const fetchNatureOfBusiness = createAsyncThunk(
	'fetchSlice/fetchNatureOfBusiness',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/ubn/app/${id}`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchNameClearanceApplications = createAsyncThunk(
	'fetchSlice/fetchNameClearanceApplications',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/app`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchOrderSummary = createAsyncThunk(
	'fetchSlice/fetchOrderSummary',
	async (id) => {
		const res = await axiosInstance
			.get(`${url}/v1/api/pym/all/e9560396-f5bf-4ee2-bdea-1233b755c21f`)
			.then((res) => res.data.data.result);
		return res;
	}
);

export const fetchSlice = createSlice({
	name: 'fetch',
	initialState,
	reducers: {
		clearNatureOfBusiness: (state) => {
			state.natureOfBusiness = [];
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchOrderSummary.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchOrderSummary.fulfilled, (state, action) => {
				state.fetching = false;
				state.orderSummary = action.payload;
			})
			.addCase(fetchOrderSummary.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchNameClearanceApplications.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchNameClearanceApplications.fulfilled, (state, action) => {
				state.fetching = false;
				state.nameClearanceApplications = action.payload;
			})
			.addCase(fetchNameClearanceApplications.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchNatureOfBusiness.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchNatureOfBusiness.fulfilled, (state, action) => {
				state.fetching = false;
				state.natureOfBusiness = action.payload;
			})
			.addCase(fetchNatureOfBusiness.rejected, (state) => {
				state.fetching = false;
			})

			.addCase(fetchCategory.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchCategory.fulfilled, (state, action) => {
				state.fetching = false;
				state.categories = action.payload;
			})
			.addCase(fetchCategory.rejected, (state) => {
				state.fetching = false;
			});
	},
});

export default fetchSlice.reducer;
export const { clearNatureOfBusiness } = fetchSlice.actions;
