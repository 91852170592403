export default function HelpStep() {
	return (
		<>
			<div className='flex flex-col mt-[2.5rem]'>
				<div className='flex flex-col'>
                    <span className="label_1">Call Us</span>
                    <span className="label_1 !font-[400]">Call Centre: 0709 932 300</span>
                </div>

                <div className='flex flex-col mt-[1rem]'>
                    <span className="label_1">Contact Us</span>
                    <span className="label_1 !font-[400]">Email: info@mmmd.gov.zm</span>
                </div>
			</div>
		</>
	);
}
