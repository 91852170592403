import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import globalReducer from '../features/global/globalSlice';
import fetchReducer from '../features/fetch/fetchSlice';
import saveReducer from '../features/save/saveSlice';
import storeReducer from '../features/store/storeSlice';
import fetchIdReducer from '../features/fetch_id/fetchIdSlice';
import deleteReducer from '../features/delete/deleteSlice';
import serviceReducer from "../features/services/serviceSlice"
import dlReducer from "../features/services/dlSlice"

const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	auth: authReducer,
	global: globalReducer,
	fetch: fetchReducer,
	save: saveReducer,
	store: storeReducer,
	fetchId: fetchIdReducer,
	delete: deleteReducer,
	serv:serviceReducer,
	dl:dlReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export const persistor = persistStore(store);
