import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	saving: false,
};

export const save = createAsyncThunk('saveSlice/save', async (data) => {
	let saveUrl = data.url;
	delete data.url;
	const res = await axiosInstance
		.post(`${url}${saveUrl}`, data)
		.then((res) => res.data);
	return res;
});

export const saveFile = createAsyncThunk('saveSlice/saveFile', async (data) => {
	const res = await axiosInstance
		.post(`${url}/v1/api/att/upload`, data, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		})
		.then((res) => res.data);
	return res;
});

export const saveSlice = createSlice({
	name: 'save',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(save.pending, (state) => {
				state.saving = true;
			})
			.addCase(save.fulfilled, (state, action) => {
				state.saving = false;
			})
			.addCase(save.rejected, (state) => {
				state.saving = false;
			})

			.addCase(saveFile.pending, (state) => {
				state.saving = true;
			})
			.addCase(saveFile.fulfilled, (state, action) => {
				state.saving = false;
			})
			.addCase(saveFile.rejected, (state) => {
				state.saving = false;
			});
	},
});

export default saveSlice.reducer;
export const {} = saveSlice.actions;
