import { notification } from "antd";
import toast from "react-hot-toast";

{
  /* <Button
				onClick={() =>
					openNotificationWithIcon({
						key: '1',
						type: 'success',
						title: 'Title',
						content: 'Content',
					})
				}>
				Success
			</Button>
			<Button
				onClick={() =>
					openNotificationWithIcon({
						key: '2',
						type: 'info',
						title: 'Title',
						content: 'Content',
					})
				}>
				Info
			</Button>
			<Button
				onClick={() =>
					openNotificationWithIcon({
						key: '3',
						type: 'warning',
						title: 'Title',
						content: 'Content',
					})
				}>
				Warning
			</Button>
			<Button
				onClick={() =>
					openNotificationWithIcon({
						key: '4',
						type: 'error',
						title: 'Title',
						content: 'Content',
					})
				}>
				Error
			</Button> */
}

export const openNotificationWithIcon = (alertObj) => {
  notification[alertObj.type]({
    duration: 7,
    placement: "bottomLeft",
    stack: true,
    threshold: 1,
    key: alertObj.key,
    message: alertObj.title,
    description: alertObj.content,
  });
};

export const customToast = (toastObj) => {
  toast.custom((t) => (
    <div
      style={{
        border: `2px solid ${
          toastObj.bdColor === "error"
            ? "#C8001B"
            : toastObj.bdColor === "success"
            ? "#02A548"
            : "#285ff6"
        }`,
      }}
      className={`max-w-md w-full bg-white rounded-[3px] flex`}
    >
      <div className="flex-1 w-0 p-4">
        <div className="flex items-start">
          {toastObj?.img ? (
            <>
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                  alt=""
                />
              </div>
            </>
          ) : null}

          <div className="ml-3 flex-1">
            {toastObj?.name ? (
              <>
                <p className="mt-1 text-[21px] text-blk3 font-bold">
                  {toastObj?.name}
                </p>
              </>
            ) : null}
            <p className="mt-1 text-[18px] text-blk3 font-semibold">
              {toastObj?.content}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          borderLeft: `2px solid ${
            toastObj.bdColor === "error"
              ? "#C8001B"
              : toastObj.bdColor === "success"
              ? "#02A548"
              : "#285ff6"
          }`,
        }}
        className="flex"
      >
        <button
          onClick={() => toast.dismiss(t.id)}
          className="w-full p-4 flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.408 11.9963L17.708 7.70628C17.8963 7.51798 18.0021 7.26258 18.0021 6.99628C18.0021 6.72998 17.8963 6.47458 17.708 6.28628C17.5197 6.09798 17.2643 5.99219 16.998 5.99219C16.7317 5.99219 16.4763 6.09798 16.288 6.28628L11.998 10.5863L7.70799 6.28628C7.51968 6.09798 7.26429 5.99219 6.99799 5.99219C6.73168 5.99219 6.47629 6.09798 6.28799 6.28628C6.09968 6.47458 5.99389 6.72998 5.99389 6.99628C5.99389 7.26258 6.09968 7.51798 6.28799 7.70628L10.588 11.9963L6.28799 16.2863C6.19426 16.3792 6.11986 16.4898 6.06909 16.6117C6.01833 16.7336 5.99219 16.8643 5.99219 16.9963C5.99219 17.1283 6.01833 17.259 6.06909 17.3809C6.11986 17.5027 6.19426 17.6133 6.28799 17.7063C6.38095 17.8 6.49155 17.8744 6.61341 17.9252C6.73527 17.9759 6.86597 18.0021 6.99799 18.0021C7.13 18.0021 7.2607 17.9759 7.38256 17.9252C7.50442 17.8744 7.61502 17.8 7.70799 17.7063L11.998 13.4063L16.288 17.7063C16.3809 17.8 16.4915 17.8744 16.6134 17.9252C16.7353 17.9759 16.866 18.0021 16.998 18.0021C17.13 18.0021 17.2607 17.9759 17.3826 17.9252C17.5044 17.8744 17.615 17.8 17.708 17.7063C17.8017 17.6133 17.8761 17.5027 17.9269 17.3809C17.9776 17.259 18.0038 17.1283 18.0038 16.9963C18.0038 16.8643 17.9776 16.7336 17.9269 16.6117C17.8761 16.4898 17.8017 16.3792 17.708 16.2863L13.408 11.9963Z"
              fill={`${
                toastObj.bdColor === "error"
                  ? "#C8001B"
                  : toastObj.bdColor === "success"
                  ? "#02A548"
                  : "#285ff6"
              }`}
            />
          </svg>
        </button>
      </div>
    </div>
  ));
};

export const formatMoney = (money) => {
  let keCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "KES",
  });
  if (!money) {
    return "";
  }
  return keCurrency.format(money);
};
export const dateForHumans = (timestamp) => {
  if (!timestamp) return;
  let date = new Date(timestamp);

  let day = date.getDate();
  let month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
  let year = date.getFullYear();

  let dayString = day + getDaySuffix(day);

  return `${dayString} ${month} ${year}`;
};
function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
