import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanBillSummary, fetchBills, saveInvoice, setBillSummary } from "../../features/services/serviceSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Payment() {
  const { servTypeId, billsData ,singlePdl} = useSelector((state) => state.serv);
  const { user} = useSelector((state) => state.auth);
 console.log("billsDatapayment",billsData)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fetchBillsData() {
    dispatch(
      fetchBills({
        id: servTypeId,
      })
    );
  }

  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick =async (item) => {
    await dispatch(setBillSummary(item))
    setSelectedButton(item?.bilId);
  };

  const handlePayment = async () => {
    const res = await dispatch(
      saveInvoice({
        invoId: null,
        invoCreatedBy: "8d5d5ee5-1ceb-47c5-b07e-006341f9c2a8",
        servMenuId: servTypeId,
        invoBilId: selectedButton,
        invoCustName: "Job Kinyua",
        invoServId:singlePdl?.servId,

        url: "serv/save_invoice",
      })
    );
    if (res?.payload?.success) {
      toast.success(res.payload?.messages?.message);
      navigate('/payment-finish')
    } else {
      toast.error(res.payload?.messages?.message);
    }
  };

 function cleanBillSummaryData(){ 
  dispatch(cleanBillSummary())
 }

  useEffect(() => {
    fetchBillsData();
  }, []);
  return (
    <>
      <div className="flex-wrap justify-between gap-[1rem] flex">
        {billsData &&
          billsData?.map((item) => (
            <div
              key={item.bilId}
              onClick={() => handleButtonClick(item)}
              className={`service-card  ${
                selectedButton === item.bilId
                  ? "border !border-[#388E3C]"
                  : "inherit"
              } flex-grow px-5 py-5`}
            >
              <div className="label_1 text-left ">{item?.pymName}</div>
              <div className="mt-[20px] Label_3 w-full text-left">
                zmw {item?.bilCost}
              </div>
              <div className="mt-[26px] w-full h-[3px] bg-[#388E3C]"></div>
              <div className="mt-[10px] Label_3 w-full text-left">
                pay with {item?.pymDesc}
              </div>
            </div>
          ))}
      </div>

      <div className="flex justify-center text-center items-center w-full">
        <div className="mt-[40px] flex flex-col gap-x-4">
          <div className="w-[209px]">
            <button
              onClick={selectedButton === null ? null : handlePayment}
              key="submit"
              className={`cstm-btn ${
                selectedButton === null
                  ? "!bg-[#fff] !text-[#374151] border border-[#D1D5DB]"
                  : "inherit"
              }`}
            >
              Finish Payment
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
