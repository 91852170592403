import { useSelector } from "react-redux";

export default function ApplicationNameGuidelinesTab() {
  const { billSummary } = useSelector((state) => state.serv);

  console.log("billSummary", billSummary);
  return (
    <>
      <div className="flex flex-col">
        <span className="heading_4 !text-blk3 mt-[.5rem]">Overall Summary</span>
        <div className="flex flex-col mt-[1.5rem] gap-y-[20px]">
          <div>
            <div className="flex justify-between">
              <span className="label_1">Service</span>
              <span className="label_1">Amount</span>
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <span>Service Total</span>
              <span>{billSummary?.bilCost}</span>
            </div>
          </div>

          <div>
            <div className="flex justify-between">
              <span>Transaction Fee</span>
              <span>{billSummary?.bilTransactionFee}</span>
            </div>
          </div>

          <div>
            <div className="flex justify-between">
              <span className="label_1">Total(ZMW)</span>
              <span className="label_1">{billSummary?.bilTotal}</span>
            </div>
          </div>
        </div>
        {/* <ul className="mt-[1.5rem] list-disc ml-[2rem]">
					<li className="paragraph_1 !text-blk3 mt-[.3rem]">NRC</li>
					<li className="paragraph_1 !text-blk3 mt-[.3rem]">Valid Tax clearance certificate</li>
					<li className="paragraph_1 !text-blk3 mt-[.3rem]">
						Duly completed Form I with geographical coordinates of the area of
						interest which should fit the predefined cadastral grid as per form
						I.
					</li>
					<li className="paragraph_1 !text-blk3 mt-[.3rem]">Environmental commitment plan</li>
				</ul> */}
      </div>
    </>
  );
}
