import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  serviceLoading: false,
  serviceData: [],
  provincesData: [],
  servRequirementData: [],
  servTypeId: "",
 
  saving: false,

  registrationData: {},
  singlePdl: {},
  billsData: [],

  billSummary: {},
};

export const fetchServices = createAsyncThunk(
  "authSlice/fetchServices",
  async () => {
    const res = await axiosInstance
      .get(`${url}/role/api/services/2`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const fetchProvinces = createAsyncThunk(
  "authSlice/fetchProvinces",
  async () => {
    const res = await axiosInstance
      .get(`${url}/serv/fetch_provinces`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const fetchServiceRequirements = createAsyncThunk(
  "authSlice/fetchServiceRequirements",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/serv/fetch_service_requirements/${data?.id}`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const save = createAsyncThunk(
  "user/save",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      } 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveInvoice = createAsyncThunk(
  "user/saveInvoice",
  async (data, { rejectWithValue }) => {
    let saveUrl = data.url;
    delete data.url;
    try {
      const response = await axiosInstance.post(`${url}/${saveUrl}`, data);
      if (!response.data.success) {
        return rejectWithValue(response.data);
      } 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBills = createAsyncThunk(
  "authSlice/fetchBills",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/serv/fetch_bills/${data?.id}`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServTypeId: (state, action) => {
      state.servTypeId = action?.payload;
    },
    setBillSummary: (state, action) => {
      state.billSummary = action?.payload;
    },
   

    
    cleanServTypeId: (action, state) => {
      state.servTypeId = initialState.servTypeId;
     
    },

    setRegistrationData: (state, action) => {
      state.registrationData = { ...state.registrationData, ...action.payload };
    },

    cleanRegistrationData: (state, action) => {
      state.registrationData = initialState.registrationData;
    },

    cleanBillSummary: (action, state) => {
      state.billSummary = initialState.billSummary;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchServices.pending, (state) => {
        state.serviceLoading = true;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.serviceLoading = false;
        state.serviceData = action.payload;
      })
      .addCase(fetchServices.rejected, (state) => {
        state.serviceLoading = false;
        state.serviceData = [];
      })

      .addCase(fetchProvinces.pending, (state) => {
        state.serviceLoading = true;
      })
      .addCase(fetchProvinces.fulfilled, (state, action) => {
        state.serviceLoading = false;
        state.provincesData = action.payload;
      })
      .addCase(fetchProvinces.rejected, (state) => {
        state.serviceLoading = false;
        state.provincesData = [];
      })

      .addCase(fetchServiceRequirements.pending, (state) => {
        state.serviceLoading = true;
      })
      .addCase(fetchServiceRequirements.fulfilled, (state, action) => {
        state.serviceLoading = false;
        state.servRequirementData = action.payload;
      })
      .addCase(fetchServiceRequirements.rejected, (state) => {
        state.serviceLoading = false;
        state.servRequirementData = [];
      })

      .addCase(save.pending, (state) => {
        state.saving = true;
      })
      .addCase(save.fulfilled, (state, action) => {
        state.saving = false;
        state.singlePdl = action?.payload?.jsonData;
      })
      .addCase(save.rejected, (state) => {
        state.saving = false;
      })

      .addCase(fetchBills.pending, (state) => {
        state.serviceLoading = true;
      })
      .addCase(fetchBills.fulfilled, (state, action) => {
        state.serviceLoading = false;
        state.billsData = action.payload;
      })
      .addCase(fetchBills.rejected, (state) => {
        state.serviceLoading = false;
        state.billsData = [];
      })


      .addCase(saveInvoice.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveInvoice.fulfilled, (state, action) => {
        state.saving = false; 
      })
      .addCase(saveInvoice.rejected, (state) => {
        state.saving = false;
      })
  },
});

export default serviceSlice.reducer;
export const { setServTypeId, cleanServTypeId, setRegistrationData,
  cleanBillSummary,setBillSummary,cleanRegistrationData
 } =
  serviceSlice.actions;
