import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import svg1 from '../../assets/svg/svg1.svg';
import svg2 from '../../assets/svg/svg2.svg';
import svg3 from '../../assets/svg/svg3.svg';
import { setIsGrid } from '../../features/global/globalSlice';
import GridServicesCard from '../../components/GridServicesCard';
import ColumnServicesCard from '../../components/ColumnServicesCard';
import { Input } from 'antd'; 
import { cleanServTypeId, fetchServices,  setServTypeId } from '../../features/services/serviceSlice';
import BlackNav from '../../components/BlackNav';

const text = 'Companies Form 1 - Application For Name Clearance';

const isGridSvg = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='33'
		height='32'
		viewBox='0 0 33 32'
		fill='none'>
		<path
			d='M15.2544 7V13C15.2544 13.5304 15.0437 14.0391 14.6686 14.4142C14.2935 14.7893 13.7848 15 13.2544 15H7.25439C6.72396 15 6.21525 14.7893 5.84018 14.4142C5.46511 14.0391 5.25439 13.5304 5.25439 13V7C5.25439 6.46957 5.46511 5.96086 5.84018 5.58579C6.21525 5.21071 6.72396 5 7.25439 5H13.2544C13.7848 5 14.2935 5.21071 14.6686 5.58579C15.0437 5.96086 15.2544 6.46957 15.2544 7ZM25.2544 5H19.2544C18.724 5 18.2153 5.21071 17.8402 5.58579C17.4651 5.96086 17.2544 6.46957 17.2544 7V13C17.2544 13.5304 17.4651 14.0391 17.8402 14.4142C18.2153 14.7893 18.724 15 19.2544 15H25.2544C25.7848 15 26.2935 14.7893 26.6686 14.4142C27.0437 14.0391 27.2544 13.5304 27.2544 13V7C27.2544 6.46957 27.0437 5.96086 26.6686 5.58579C26.2935 5.21071 25.7848 5 25.2544 5ZM13.2544 17H7.25439C6.72396 17 6.21525 17.2107 5.84018 17.5858C5.46511 17.9609 5.25439 18.4696 5.25439 19V25C5.25439 25.5304 5.46511 26.0391 5.84018 26.4142C6.21525 26.7893 6.72396 27 7.25439 27H13.2544C13.7848 27 14.2935 26.7893 14.6686 26.4142C15.0437 26.0391 15.2544 25.5304 15.2544 25V19C15.2544 18.4696 15.0437 17.9609 14.6686 17.5858C14.2935 17.2107 13.7848 17 13.2544 17ZM25.2544 17H19.2544C18.724 17 18.2153 17.2107 17.8402 17.5858C17.4651 17.9609 17.2544 18.4696 17.2544 19V25C17.2544 25.5304 17.4651 26.0391 17.8402 26.4142C18.2153 26.7893 18.724 27 19.2544 27H25.2544C25.7848 27 26.2935 26.7893 26.6686 26.4142C27.0437 26.0391 27.2544 25.5304 27.2544 25V19C27.2544 18.4696 27.0437 17.9609 26.6686 17.5858C26.2935 17.2107 25.7848 17 25.2544 17Z'
			fill='#388E3C'
		/>
	</svg>
);

const isNotGridSvg = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='33'
		height='32'
		viewBox='0 0 33 32'
		fill='none'>
		<path
			d='M13.2544 6H7.25439C6.70211 6 6.25439 6.44772 6.25439 7V13C6.25439 13.5523 6.70211 14 7.25439 14H13.2544C13.8067 14 14.2544 13.5523 14.2544 13V7C14.2544 6.44772 13.8067 6 13.2544 6Z'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M25.2544 6H19.2544C18.7021 6 18.2544 6.44772 18.2544 7V13C18.2544 13.5523 18.7021 14 19.2544 14H25.2544C25.8067 14 26.2544 13.5523 26.2544 13V7C26.2544 6.44772 25.8067 6 25.2544 6Z'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M13.2544 18H7.25439C6.70211 18 6.25439 18.4477 6.25439 19V25C6.25439 25.5523 6.70211 26 7.25439 26H13.2544C13.8067 26 14.2544 25.5523 14.2544 25V19C14.2544 18.4477 13.8067 18 13.2544 18Z'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M25.2544 18H19.2544C18.7021 18 18.2544 18.4477 18.2544 19V25C18.2544 25.5523 18.7021 26 19.2544 26H25.2544C25.8067 26 26.2544 25.5523 26.2544 25V19C26.2544 18.4477 25.8067 18 25.2544 18Z'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

const isColumnSvg = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='33'
		height='32'
		viewBox='0 0 33 32'
		fill='none'>
		<path
			d='M12.2544 8H27.2544'
			stroke='#388E3C'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M12.2544 16H27.2544'
			stroke='#388E3C'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M12.2544 24H27.2544'
			stroke='#388E3C'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M5.25439 8H7.25439'
			stroke='#388E3C'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M5.25439 16H7.25439'
			stroke='#388E3C'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M5.25439 24H7.25439'
			stroke='#388E3C'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

const isNotColumnSvg = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='33'
		height='32'
		viewBox='0 0 33 32'
		fill='none'>
		<path
			d='M12.2544 8H27.2544'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M12.2544 16H27.2544'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M12.2544 24H27.2544'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M5.25439 8H7.25439'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M5.25439 16H7.25439'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M5.25439 24H7.25439'
			stroke='#616161'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

export default function DashBoard() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isGrid } = useSelector((state) => state.global);
	const { categories } = useSelector((state) => state.fetch);
	const {serviceData} = useSelector((state) =>state.serv)
 
	const [search, setsearch] = useState('');
	const [activeItem, setactiveItem] = useState();

	 

	async function handleActiveService(item) {
		console.log("item type: " + item?.type);
		await dispatch(setServTypeId(item?.id)) 
		if(item?.type === "PDL"){
			await navigate("/services/pdl") 
		}
		if(item?.type === "DL"){
			await navigate("/services/dl") 
		}
		
	}

	async function fetchServiceData(){
		await dispatch(fetchServices())
	}

	async function cleanServType(){
		await dispatch(cleanServTypeId())
	}
	

	useEffect(() => {}, [isGrid, categories]);

 

	useEffect(() => {
		fetchServiceData();
		cleanServType();
	}, []);

 

	return (
		<>
			<BlackNav text={'Roads Transport Safety Authority'} />
			<div className='mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem] h-full w-full'>
				<span className='heading_4 !text-blk21'>Quick menu</span>
				<div className='mt-[1.63rem] flex gap-x-[24px] items-center w-full'>
					<div className='w-[210px]'>
						<button className='services-btn'>
							<img src={svg1} alt='svg1' />
							Services
						</button>
					</div>

					<div className='w-[210px]'>
						<button
							onClick={() => navigate('/services/application-history')}
							className='history-btn'>
							<img src={svg2} alt='svg2' />
							History
						</button>
					</div>
				</div>

				<div className='mt-[46px] w-full flex items-center justify-between'>
					<div className='heading_4 !text-[#212121]'>Services</div>

					<div className='flex items-center justify-center gap-x-[24px]'>
						<Input
							value={search}
							onChange={(e) => setsearch(e.target.value)}
							placeholder='Search service'
							className='text-[16px] font-[400] flex-row-reverse'
							prefix={<img src={svg3} alt='svg3' />}
						/>
						<button onClick={() => dispatch(setIsGrid(true))}>
							{isGrid ? isGridSvg : isNotGridSvg}
						</button>
						<button onClick={() => dispatch(setIsGrid(false))}>
							{!isGrid ? isColumnSvg : isNotColumnSvg}
						</button>
					</div>
				</div>

				<div className='flex w-full'>
					{isGrid ? (
						<>
							<div className='flex items-start flex-shrink flex-wrap gap-[1.5rem] w-full'>
								{serviceData?.map((item) => {
									return (
										<GridServicesCard
											item={item}
											open={activeItem === item?.id}
											handleActiveService={handleActiveService}
										/>
									);
								})}
							</div>
						</>
					) : (
						<>
							<div className='flex flex-col w-full'>
								{serviceData?.map((item) => {
									return (
										<ColumnServicesCard
											item={item}
											open={activeItem === item?.id}
											handleActiveService={handleActiveService}
										/>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
