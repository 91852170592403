import { Breadcrumb, Form, Progress, Steps, Tabs } from 'antd';
import { Children, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import NameClearanceHelp from './NameClearanceHelp'; 
import DlApplicationForm from './DlApplicationForm';
import BlackNav from '../../components/BlackNav';
import Payment from '../payment/Payment';
import { cleanBillSummary, setBillSummary } from '../../features/services/serviceSlice';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: (
			<Link to='/services/dl'>
				Application for Driving License
			</Link>
		),
	},
	{
		title: 'Application Form',
	},
];

export default function ApplicationOfDl() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { businessDetails } = useSelector(
		(state) => state.store
	);


	const view = useRef();

	const [current, setcurrent] = useState('1');

	const tabItems = [
		{
			label: 'Application Details',
			key: '1',
			children: (
				<DlApplicationForm
					handleBack={handleBack}
					handleNext={handleNext}
				/>
			),
		},
		

		{
			label: 'Payment',
			key: '2',
			children: <Payment handleBack={handleBack} />,
		},
	];

	function handleNext() {
		console.log(current);
		let num = Number(current);
		if (num < 2) {
			num += 1;
			setcurrent(String(num));
			view?.current?.scrollIntoView();
		} else {
			setcurrent(current);
		}
	}

	function handleBack() {
		let num = Number(current);
		if (num > 0) {
			num -= 1;
			setcurrent(String(num));
			view?.current?.scrollIntoView();
		} else {
			setcurrent(current);
		}
	}

	const onChange = (value) => {
		setcurrent(value);
		view?.current?.scrollIntoView();
	};

	function cleanBillSummaryData(){ 
		dispatch(setBillSummary(''))
	   }
	useEffect(()=>{
		cleanBillSummaryData()
	},[])

 
	return (
		<>
			<BlackNav text={'Roads Transport Safety Authority'} />
			<div className='flex justify-start items-start mt-[.94rem] h-full w-full'>
				<div className='bg-white py-[2.64rem] px-[2.5rem] h-full w-full'>
					<div className='flex flex-col w-full'>
						<Breadcrumb items={items} />
						<span ref={view} className='heading_4 !text-blk21 mt-[1.94rem]'>
							The Companies Act, 2017 (Act No. 10 of 2017)
						</span>

						<span className='label_1 !text-blk21 mt-[1.69rem]'>
							The Companies (Prescribed Forms) Regulations, 2018 (Section 39)
						</span>

						<div className='p-[2.5rem] border border-[#ECECEC] mt-[2.5rem] w-full'>
							<Tabs
								activeKey={current}
								defaultActiveKey='1'
								items={tabItems}
								onChange={onChange}
							/>
						</div>
					</div>
				</div>



				<div className='hidden lg:flex lg:min-w-[30.625rem] lg:w-[30.625rem] max-w-full bg-white h-full py-[3.75rem] px-[2.5rem] ml-[.94rem]'>
					<NameClearanceHelp />
				</div>
			</div>
		</>
	);
}
