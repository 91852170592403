import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	authLoading: false,
	user: {},
	isLoggedIn: true,
	token: '',
	users: [],
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = {};
			state.isLoggedIn = false;
			state.token = '';
			localStorage.clear();
		},
		setToken: (state, action) => {
			state.token = action.payload;
			state.isLoggedIn = true;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: (builder) => {},
});

export default authSlice.reducer;
export const { logout, setToken, setUser } = authSlice.actions;
