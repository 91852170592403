import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import {
	genderData,
	identityData,
	nationalityData,
	natureData,
} from '../../../../data';
import dayjs from 'dayjs';
import PhoneInput from 'react-phone-input-2';
import {
	setApplicantDetails,
	setBusinessDetails,
} from '../../../../features/store/storeSlice';
import { useDispatch } from 'react-redux';

const { TextArea } = Input;
export default function ProposedNamesStep({
	handleBack,
	applicantDetails,
	handleNext,
}) {
	const dispatch = useDispatch();

	const [formData, setFormData] = useState({
		...applicantDetails,
	});

	const onChange = async (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	function handleSelectChange(value, formName) {
		setFormData((prevData) => ({
			...prevData,
			[formName]: value,
		}));
	}

	useEffect(() => {
		dispatch(setBusinessDetails(formData));
	}, [formData]);

	return (
		<>
			<div className='mt-[35px]'>
				<div className='mt-[40px] h-full mb-10'>
					<div className='grid grid-cols-1 gap-x-[24px]'>
						<Form.Item
							label='Proposed Name (1)'
							extra={'Enter proposed name 1'}
							style={{ width: '100%' }}
							rules={[
								{
									required: true,
									message: 'Please add proposed name',
								},
							]}>
							<Input
								required
								name='proposedName1'
								value={formData?.proposedName1}
								onChange={onChange}
								className='input'
							/>
						</Form.Item>

						<Form.Item
							label='Proposed Name (2)'
							extra={'Enter proposed name 2'}
							style={{ width: '100%' }}
							rules={[
								{
									required: true,
									message: 'Please add proposed name',
								},
							]}>
							<Input
								name='proposedName2'
								value={formData?.proposedName2}
								onChange={onChange}
								className='input'
							/>
						</Form.Item>

						<Form.Item
							label='Proposed Name (3)'
							extra={'Enter proposed name 3'}
							style={{ width: '100%' }}
							rules={[
								{
									required: true,
									message: 'Please add proposed name',
								},
							]}>
							<Input
								name='proposedName3'
								value={formData?.proposedName3}
								onChange={onChange}
								className='input'
							/>
						</Form.Item>
					</div>
					<div className='grid grid-cols-2 gap-x-[24px]'>
						<Form.Item
							label='Principal Business Activity'
							rules={[
								{
									required: true,
									message: 'Enter principal business activity',
								},
							]}
							extra={`Business activity to be classified in accordance with the ISIC Classification.A full list of the trade classification codes is available on our website: www.pacra.org.zm`}>
							<Select
								value={formData?.principalBusinessActivity}
								className='activity-select-tag activity-select-icon'
								allowClear
								style={{
									width: '100%',
									height: '3.25rem',
								}}
								onChange={(value) => {
									handleSelectChange(value, 'principalBusinessActivity');
								}}

							/>
						</Form.Item>

						<Form.Item
							label='Other Business Activity'
							rules={[
								{
									required: true,
									message: 'Enter other business activity',
								},
							]}
							extra={'Other business activity'}>
							<Select
								value={formData?.otherBusinessActivities}
								className='activity-select-tag activity-select-icon'
								allowClear
								style={{
									width: '100%',
									height: '3.25rem',
								}}
								onChange={(value) => {
									handleSelectChange(value, 'otherBusinessActivities');
								}}

							/>
						</Form.Item>

						{/* <Form.Item
							label='Name of promoter(s)'
							rules={[
								{
									required: true,
									message: 'Enter name of promoter',
								},
							]}
							extra={'Name of promoter(s)'}>
							<Input
								name='nameOfPromoter'
								value={formData?.nameOfPromoter}
								onChange={onChange}
								className='input'
							/>
						</Form.Item> */}
					</div>

					<div className='mt-[52px] flex items-center justify-center flex-col gap-y-[4px] mb-[40px]'>
						<div className='w-[184px]'>
							{/* <button
								type='button'
								onClick={() => handleNext()}
								className='cstm-btn'>
								Next
							</button> */}
							<button
								// type='button'
								className='cstm-btn'>
								Submit
							</button>
						</div>
						<div className='w-[184px]'>
							<button
								type='button'
								onClick={() => handleBack()}
								className='cstm-btn-borderless'>
								Previous
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
