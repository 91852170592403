import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import toast from "react-hot-toast";
import BlackNav from "../../components/BlackNav";
import { cleanBillSummary, cleanRegistrationData } from "../../features/services/serviceSlice";
 

function PaymentFinish() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState();

  const { authLoading, user, accType, registrationData, singleUser, saving } =
    useSelector((state) => state.auth);

  const [data, setdata] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleLogin = async () => {
    await dispatch(cleanRegistrationData());
    await navigate("/services");
  };

  async function cleanBillSummaryData(){
    dispatch(cleanBillSummary())
  }

  useEffect(()=>{
    cleanBillSummaryData()
  },[])
  return (
    <>
     <BlackNav text={'Roads Transport Safety Authority'} />
      <div className="flex justify-center items-center text-center w-full mt-[88px] lg:px-0 px-3">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          className="lg:w-[450px] w-full"
          form={form}
        >
          <div className="heading_1">Invoice generated</div>
          <div className="typography_p_agency text-[#4B5563] mt-[12px] !leading-[24px]">
            You have successfully paid for your service
          </div>

          <div className="w-[100%] mt-[52px]">
            <button className={`cstm-btn`} onClick={handleLogin}>
              Proceed to other services
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default PaymentFinish;
