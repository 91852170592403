import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './layout/Header';
import SideBar from './layout/SideBar';
import { useEffect } from 'react';
import SubHeader from './layout/SubHeader';
import BlackNav from './components/BlackNav';

export const ProtectedRoute = () => {
	const navigate = useNavigate();

	// const { isLoggedIn } = useSelector((state) => state.auth);

	// function checkStatus() {
	// 	if (!isLoggedIn) {
	// 		return navigate('/account/login');
	// 	}
	// 	return;
	// }
	// useEffect(() => {
	// 	checkStatus();
	// }, [isLoggedIn]); {/* <BlackNav text={"Patents and Companies Registration Agency"} /> */}

	return (
		<div className='flex flex-col w-full h-full'>
			<Header />
			<div className='w-full min-h-[85vh] h-[90vh] max-h-full px-[2.5rem]  pb-[2.5rem] overflow-y-auto'>
				<div className='flex flex-col items-start w-full h-full'>
					<Outlet />
				</div>
			</div>
		</div>
	);
};
