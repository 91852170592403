export default function ApplicationNameGuideFeesTab() {
	return (
		<>
			<div className='flex flex-col'>
				<div className='flex flex-col gap-[1.88rem]'>
					<div className='flex items-center mt-[2.5rem]'>
						<span className='paragraph_1 !text-blk3 w-[9.6rem]'>ITEM</span>
						<span className='paragraph_1 !text-blk3 ml-[1.88rem]'>Amount</span>
					</div>
                    <div className='flex items-center mt-[1rem]'>
						<span className='paragraph_1 !text-blk3 w-[9.6rem]'>Business name</span>
						<span className='paragraph_1 !text-blk3 ml-[1.88rem]'>ZMW 50</span>
					</div>

                    <div className='flex items-center mt-[1rem]'>
						<span className='paragraph_1 !text-blk3 w-[9.6rem]'>Local company</span>
						<span className='paragraph_1 !text-blk3 ml-[1.88rem]'>ZMW 20</span>
					</div>

                    <span className="paragraph_1 !text-blk3 mt-[1rem]">A transaction fee may be added, depending on your mode of payment.</span>
				</div>
			</div>
		</>
	);
}
