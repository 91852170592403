import { Link } from 'react-router-dom';
import svg6 from '../assets/svg/svg6.svg';
import svg7 from '../assets/svg/svg7.svg';

export default function GridServicesCard({ item, handleActiveService, open }) { 
	return (
		<>
			<button
				onClick={() => handleActiveService(item)}
				className='service-card flex-grow px-[36.75px] pt-[36px] pb-[1rem] mt-[1.25rem] !min-h-[250px]'>
				<div className='label_1 text-left '>{item?.text}</div>
				<div className='mt-[16px] service-line'></div>
				<div className='mt-[20px] Label_3 w-full text-left'>
					{item?.parentDesc}
				</div>
				<div className='flex flex-end justify-end cursor-pointer'> 
				<span className={`text-[#222]`}>
							<img src={svg6} alt='svg6' />
						</span>
				</div>
				 
			</button>
		</>
	);
}
