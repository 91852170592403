import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,  
  pdlData: {}, 
  dlDetails:{}
};

 
export const fetchPDl = createAsyncThunk(
  "authSlice/fetchPDl",
  async (data) => {
    const res = await axiosInstance
      .get(`${url}/serv/fetch_services`)
      .then((res) => res.data.data.result);
    return res;
  }
);

export const dlSlice = createSlice({
  name: "dl",
  initialState,
  reducers: {
     setDlDetails: (state, action) => {
      state.dlDetails = action?.payload;
    },
     
    cleanDlDetails: (action, state) => {
      state.dlDetails = initialState.dlDetails;
     
    },

  },
  extraReducers: (builder) => {
    builder 

      .addCase(fetchPDl.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPDl.fulfilled, (state, action) => {
        state.loading = false;
        state.pdlData = action.payload[0];
      })
      .addCase(fetchPDl.rejected, (state) => {
        state.loading = false;
        state.pdlData = {};
      })
 
  },
});

export default dlSlice.reducer;
export const {  setDlDetails,cleanDlDetails
 } =
  dlSlice.actions;
