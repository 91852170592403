import React, { useEffect } from "react";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { fetchServiceRequirements } from "../../../features/services/serviceSlice";
import { useDispatch, useSelector } from "react-redux";
import BlackNav from "../../../components/BlackNav";

const items = [
  {
    title: <Link to="/services">Services</Link>,
  },
  {
    title: "Application for Provisional Driving License",
  },
];

export default function PdlOverviewPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { servRequirementData, servTypeId } = useSelector(
    (state) => state.serv
  );
 
  function handleApply() {
    navigate("/services/pdl/application");
  }

  function fetchServiceRequirementsData() {
    dispatch(
      fetchServiceRequirements({
        id: servTypeId,
      })
    );
  }

  useEffect(() => {
    fetchServiceRequirementsData();
  }, []);

  return (
    <>
      <BlackNav text={"Roads Transport Safety Authority"} />
      <div className="mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem] h-full w-full">
        <Breadcrumb items={items} />

        <div className="heading_4 mt-[1.94rem] !text-blk21 capitalize">
          Application for Provisional Drving License
        </div>

        {servRequirementData?.length > 0 &&
          servRequirementData?.map((item) => (
            <>
              <div className="label_1 mt-[1.69rem]">{item?.serColumn}</div>

              <div className="label_2 mt-[1.2rem] flex flex-col">
                {item?.serDesc.split(",")?.map((sentence, index) => (
                  <div key={index}>
                    {sentence.trim()}
                    {index !== item?.serDesc?.split(",").length - 1 && <br />}
                  </div>
                ))}
              </div>
            </>
          ))}

        <div
          style={{
            width: "100%",
            height: "11.125rem",
            display: "flex",
            padding: "1.25rem",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            flex: "1 0 0",
            background: "#FFF",
          }}
          className="mt-[1.25rem]"
        >
          <div className="flex flex-col w-fit">
            <button onClick={handleApply} className="cstm-btn">
              Apply
            </button>
            <button
              onClick={() => navigate("/services")}
              className="cstm-btn-borderless"
            >
              Back to services
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
