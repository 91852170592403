export const genderData = [
	{
		label: 'Male',
		value: 'Male',
	},
	{
		label: 'Female',
		value: 'Female',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

export const nationalityData = [
	{
		label: 'Zambian',
		value: 'Zambian',
	},
	{
		label: 'Kenyan',
		value: 'Kenyan',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

export const identityData = [
	{
		label: 'NRC ',
		value: 'NRC ',
	},
	{
		label: 'Passport',
		value: 'Passport',
	},
	{
		label: 'Resident Permit',
		value: 'Resident Permit',
	},
];
export const zambiaIdentityData = [
	{
		label: 'NRC ',
		value: 'NRC ',
	}, 
];

export const natureData = [
	{
		label: 'Company ',
		value: 'Company ',
	},
	{
		label: 'Co-operative',
		value: 'Co-operative',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

 
export const countryData = [
	{
		label: 'Zambia ',
		value: 'Zambia ',
	},
	{
		label: 'Kenya',
		value: 'Kenya',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

export const companyTypeData = [
	{
		label: 'Business Name',
		value: 'Business Name',
	},
	{
		label: 'Local Company',
		value: 'Local Company',
	},
	{
		label: 'Foreign Company',
		value: 'Foreign Company',
	},
];

export const companyCategoryData = [
	{
		id: 'Business Name',
		label: 'By Individual/Firm',
		value: 'By Individual/Firm',
	},
	{
		id: 'Business Name',
		label: 'By Corporation/Other',
		value: 'By Corporation/Other',
	},
	{
		id: 'Local Company',
		label: 'Limited by Shares',
		value: 'Limited by Shares',
	},
	{
		id: 'Local Company',
		label: 'Limited by Guarantee',
		value: 'Limited by Guarantee',
	},
	{
		id: 'Local Company',
		label: 'Guarantee without the word limited',
		value: 'Guarantee without the word limited',
	},
	{
		id: 'Local Company',
		label: 'Public',
		value: 'Public',
	},
	{
		id: 'Local Company',
		label: 'Unlimited',
		value: 'Unlimited',
	},
	{
		id: 'Foreign Company',
		label: 'Foreign Limited by Shares',
		value: 'Foreign Limited by Shares',
	},
	{
		id: 'Foreign Company',
		label: 'Foreign Limited by Guarantee',
		value: 'Foreign Limited by Guarantee',
	},
	{
		id: 'Foreign Company',
		label: 'Foreign Unlimited',
		value: 'Foreign Unlimited',
	},
];

export const busimessClassData = [
	{
		label: 'Ordinary Company ',
		value: 'Ordinary Company ',
	},
	{
		label: 'Local Bank',
		value: 'Local Bank',
	},
	{
		label: 'Bureau de change',
		value: 'Bureau de change',
	},
	{
		label: 'Insurance Company',
		value: 'Insurance Company',
	},
	{
		label: 'Insurance Broker',
		value: 'Insurance Broker',
	},
	{
		label: 'Re-Insurance Company',
		value: 'Re-Insurance Company',
	},
	{
		label: 'Other Financial Institution',
		value: 'Other Financial Institution',
	},
];

export const businessDataLevel1 = [
	{
		label: 'Agriculture',
		value: 'Agriculture',
	},
	{
		label: 'IT',
		value: 'IT',
	},
];

export const businessDataLevel2 = [
	{
		id: 'Agriculture',
		label: 'Crop production',
		value: 'Crop production',
	},
	{
		id: 'Agriculture',
		label: 'Animal production',
		value: 'Animal production',
	},
	{
		id: 'IT',
		label: 'Computer Programming',
		value: 'Computer Programming',
	},
];

export const businessDataLevel3 = [
	{
		id: 'Crop production',
		label: 'Mixed farming',
		value: 'Mixed farming',
	},
	{
		id: 'Animal production',
		label: 'Animal production',
		value: 'Animal production',
	},
	{
		id: 'Computer Programming',
		label: 'Computer Programming and consultancy',
		value: 'Computer Programming and consultancy',
	},
];

export const businessDataLevel4 = [
	{
		id: 'Mixed farming',
		label: 'Mixed farming',
		value: 'Mixed farming',
	},
	{
		id: 'Animal production',
		label: 'Raising of paultry',
		value: 'Raising of paultry',
	},
	{
		id: 'Animal production',
		label: 'Raising of cattle',
		value: 'Raising of cattle',
	},
	{
		id: 'Computer Programming and consultancy',
		label: 'Computer Programming and consultancy',
		value: 'Computer Programming and consultancy',
	},
	{
		id: 'Computer Programming and consultancy',
		label: 'Other IT services',
		value: 'Other IT services',
	},
];

export const pdlClassData = [
	{
		label: 'Class A',
		value: 'Class A',
	},
	{
		label: 'Class B',
		value: 'Class B',
	},
	{
		label: 'Class B',
		value: 'Class B',
	},
	{
		label: 'Class BCE',
		value: 'Class BCE',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

 
