import { Breadcrumb } from 'antd';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const items = [
	{
		title: <Link to='/services'>Services</Link>,
	},
	{
		title: <Link to='/services/application-history'>Application History</Link>,
	},
	{
		title: 'Application',
	},
];

export default function ViewApplicationHistory() {
	const view = useRef();

	useEffect(() => {
		view?.current?.scrollIntoView();
	}, []);

	return (
		<>
			<div
				ref={view}
				className='mt-[.94rem] bg-white py-[2.64rem] px-[2.5rem] h-full'>
				<div className='flex items-center justify-between'>
					<div className='flex flex-col w-full'>
						<Breadcrumb items={items} />
						<span ref={view} className='heading_4 !text-blk21 mt-[1.94rem]'>
							Ref: RP-CVSVSAA
						</span>

						<span className='label_1 !text-blk21 mt-[1.69rem]'>
							Application For Name Clearance
						</span>
					</div>
					<button className='cstm-btn-2 w-auto'>Edit application</button>
				</div>

				<div className='mt-[3.75rem] border border-[#EAECF0]'>
					<div className='application_details_header'>
						<span className='label_2'>Application Details</span>
					</div>

					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>Service</span>
						<span className='paragraph_2 w-full'>
							Application For Name Clearance
						</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Application ID
						</span>
						<span className='paragraph_2 w-full'>RP-CVSVSAA</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>Stage</span>
						<span className='paragraph_2 w-full'>Processing</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Date Created
						</span>
						<span className='paragraph_2 w-full'>22nd March 2024</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Date Submitted
						</span>
						<span className='paragraph_2 w-full'>22nd March 2024</span>
					</div>
					<div className='flex items-center application_details_body'>
						<span className='label_3 w-[14.125rem] flex shrink-0'>
							Last Updated
						</span>
						<span className='paragraph_2 w-full'>22nd March 2024</span>
					</div>
				</div>
			</div>
		</>
	);
}
