import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	fetching: false,
	fetchObj: {},
	viewObj: {},
};

export const fetchId = createAsyncThunk('fetchIdSlice/fetch', async (data) => {
	let fetchUrl = data.url;
	delete data.url;
	const res = await axiosInstance
		.get(`${url}${fetchUrl}`)
		.then((res) => res.data.data.result);
	return res;
});

export const fetchIdSlice = createSlice({
	name: 'fetchId',
	initialState,
	reducers: {
		clearFetchObject: (state) => {
			state.fetchObj = {};
		},
		setViewObj: (state, action) => {
			state.viewObj = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchId.pending, (state) => {
				state.fetching = true;
			})
			.addCase(fetchId.fulfilled, (state, action) => {
				state.fetching = false;
				state.fetchObj = action.payload;
			})
			.addCase(fetchId.rejected, (state) => {
				state.fetching = false;
			});
	},
});

export default fetchIdSlice.reducer;
export const { clearFetchObject, setViewObj } = fetchIdSlice.actions;
